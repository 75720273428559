import React, { useState, useEffect, useRef } from 'react';
import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import TextField from '@material-ui/core/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Typography } from '@material-ui/core';

function AssessmentsRadioOptions({ options, label, value, onChange }) {
  const handleChange = (event) => {
    if (event.target.checked) {
      onChange([...value, event.target.value]);
    } else {
      onChange(value.filter(item => item !== event.target.value));
    }
  };

  return options && options.length > 0 ? (
    <FormControl component="fieldset">
      <FormLabel style={{ paddingTop: "20px" }} component="legend">{label}</FormLabel>
      <FormGroup row>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            control={<Checkbox checked={value.includes(option.value)} onChange={handleChange} value={option.value} />}
            label={option.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  ) : null;
}

export default function IndividualChildAssessment({ index, updateChildData, childData }) {

  console.log("child data")
  console.log(childData)
  console.log(childData ? "We have" : "We don't have")

  const [studentFirstName, setStudentFirstName] = useState(childData?.studentFirstName || "");
  const [studentSurname, setStudentSurname] = useState(childData?.studentSurname || "");
  const [school, setSchool] = useState(childData?.school || "");
  const [schoolYear, setSchoolYear] = useState(childData?.schoolYear || "");
  const [dob, setDob] = useState(childData?.studentDob ? childData.studentDob.split('T')[0] : "");
  const [assessmentDate, setAssessmentDate] = useState(childData?.assessmentDate || "");
  const [timeValue, setTimeValue] = useState(childData?.assessmentStartTime ? new Date(`1970-01-01T${childData.assessmentStartTime}`) : null);
  const [selectedValue, setSelectedValue] = useState(childData?.assessmentsBooked?.map(a => a.assessmentSubject) || []);
  var assessmentStudentId = childData?.assessmentStudentId;
  var assessmentsBooked = childData?.assessmentsBooked

  const prevChildData = useRef();
  
  

  useEffect(() => {


    const childData = {
      assessmentStudentId,
      assessmentsBooked,
      studentFirstName: studentFirstName,
      studentSurname: studentSurname,
      school,
      schoolYear,
      dob,
      assessmentDate,
      timeValue,
      selectedValue,
    };
    if (JSON.stringify(childData) !== JSON.stringify(prevChildData.current)) {
      updateChildData(index, childData);
      prevChildData.current = childData;
    }
  }, [studentFirstName, studentSurname, school, schoolYear, dob, assessmentDate, timeValue, selectedValue]);

  const handleAssessmentsSubjectsChange = (newValue) => {
    setSelectedValue(newValue);
  };

  const handleTimeChange = (newValue) => {
    setTimeValue(newValue);
  };

  function getOptions() {

    console.log("School year")
    console.log(childData?.schoolYear)

    if (schoolYear == undefined && childData?.schoolYear) {
      console.log("Got null")
      return null;
    }

    if (schoolYear == undefined) {
      console.log("Got null so now setting")
      schoolYear = childData?.schoolYear
    }

    const assessmentChoices = [];

    if (["1", "2", "3"].includes(schoolYear)) {
      assessmentChoices.push({ label: 'Maths', value: 'MATHS' });
      assessmentChoices.push({ label: 'English', value: 'ENGLISH' });
    }

    if (["4", "5"].includes(schoolYear)) {
      assessmentChoices.push({ label: 'Maths', value: 'MATHS' });
      assessmentChoices.push({ label: 'English', value: 'ENGLISH' });
      assessmentChoices.push({ label: 'Verbal & Non-Verbal Reasoning', value: 'VR_NVR' });
    }

    if (["6", "7", "8", "9", "10", "11"].includes(schoolYear)) {
      assessmentChoices.push({ label: 'Maths', value: 'MATHS' });
      assessmentChoices.push({ label: 'English', value: 'ENGLISH' });
      assessmentChoices.push({ label: 'Science', value: 'SCIENCE' });
    }

    return assessmentChoices;
  }

  return (
    <div>
      <Typography variant="h5" style={{ paddingTop: "40px" }}>
        Child Details
      </Typography>

      <div className="relative w-full" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flexBasis: '45%' }}>
          <TextField
            id="studentFirstName"
            name="studentFirstName"
            label="Student First Name"
            value={studentFirstName}
            onChange={e => setStudentFirstName(e.target.value)}
            multiline
            maxRows={30}
          />
        </div>

        <div style={{ flexBasis: '45%' }}>
          <TextField
            id="studentSurname"
            name="studentSurname"
            label="Student Last Name"
            multiline
            maxRows={30}
            value={studentSurname}
            onChange={e => setStudentSurname(e.target.value)}
          />
        </div>
      </div>

      <div>
        <TextField
          id="school"
          name="school"
          label="School"
          multiline
          maxRows={30}
          value={school}
          onChange={e => setSchool(e.target.value)}
          style={{ width: '50%' }}
        />
      </div>

      <div className="relative w-full" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flexBasis: '45%' }}>
          <TextField
            id="schoolYear"
            name="schoolYear"
            label="School Year"
            type="number"
            value={schoolYear}
            onChange={e => setSchoolYear(e.target.value)}
          />
        </div>

        <div style={{ flexBasis: '45%' }}>
          <TextField
            id="dob"
            name="dob"
            label="Date of Birth"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={dob}
            onChange={e => setDob(e.target.value)}
          />
        </div>
      </div>

      <div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AssessmentsRadioOptions
            label="Assessments Booked"
            name="assessmentsBooked"
            options={getOptions()}
            value={selectedValue}
            onChange={handleAssessmentsSubjectsChange}
          />
        </LocalizationProvider>
      </div>

      <div className="relative w-full" style={{ display: 'flex', justifyContent: 'space-between', paddingTop: "16px" }}>
        <div style={{ flexBasis: '45%' }}>
          <TextField
            id="assessmentDate"
            name="assessmentDate"
            label="Assessment Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={assessmentDate}
            onChange={e => setAssessmentDate(e.target.value)}
          />
        </div>

        <div style={{ flexBasis: '45%' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label="Assessment Time"
              id="assessmentTime"
              name="assessmentTime"
              value={timeValue}
              onChange={handleTimeChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>

      {childData ? 
      <>
        <div className="relative w-full" style={{ display: 'flex', justifyContent: 'space-between', paddingTop: "25px" }}>
            Assessment Information Email Sent: {childData.studentAssessmentInformationEmailSent ? "Yes" : "No"}
        </div>
        <div className="relative w-full" style={{ display: 'flex', justifyContent: 'space-between', paddingTop: "5px" }}>
            Confirmed Attendance for Assessment: {childData.confirmedAttendanceForAssessment ? "Yes" : "No"}
        </div>
        <div className="relative w-full" style={{ display: 'flex', justifyContent: 'space-between', paddingTop: "5px" }}>
            Attended Assessment: {childData.attendedAssessment ? "Yes" : "No"}
        </div>
      </> 
      :
      <></>}


    </div>
  );
}
