import axios from 'axios'
import authHeader from "./auth-header";


const LOCAL_HOST = 'https://localhost:8443'
const PRODUCTION_PORT = 'https://whizzdom.co.uk:8443'
const LOCAL_OR_PRODUCTION = PRODUCTION_PORT;
const senderEmailApiToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZjMwY2UwZWI2MGNlOGYwODQyYzkyMTZjZGQwYjcwMGQ2ZDNmOWYwOTk3ZDlmN2QzZDQ4NzA0ODAwMmJiZGQxZWRlNjcxOTJkOTE5Y2VlNmUiLCJpYXQiOjE2NTc1Mjk2NjkuMDM4NjEsIm5iZiI6MTY1NzUyOTY2OS4wMzg2MTksImV4cCI6NDgxMTEzMzI2OS4wMzU5NDEsInN1YiI6Ijc0MTI0MiIsInNjb3BlcyI6W119.dVuzJsyZsWGgiDDx_WNT2z25We4-I63ePYrpB6-Hf515XvLYqG2Iy1GsfgIv2Er8qS-k0lZbynrEpyKVzrNsUg"

const POSTMAN_CONVERSION_API = "https://graph.facebook.com/v15.0/631827471693892/events?access_token=EAARb48tiFHcBACXkoNBrZB7CRZCNDJT4jze0ZBRfaj7CaocBuYQ7UKyljNCuuGxroU8efLUguJ0O7K7nJxt1nZAO7bDySmZCb3O2F4ARo4RU1tC39ZA8AGjp3ctXRokhWW56IXjgZAmsoZCza9G7SbSfj7hxX9F1xyTMzDM0YISbH5pMrdgduTIRPRK1IeKReoYZD"
const FB_CONVERSION_API_VERSION = "v15.0"
const FB_PIXEL_ID = "631827471693892"
const FB_ACCESS_KEY_TOKEN = "EAARb48tiFHcBACXkoNBrZB7CRZCNDJT4jze0ZBRfaj7CaocBuYQ7UKyljNCuuGxroU8efLUguJ0O7K7nJxt1nZAO7bDySmZCb3O2F4ARo4RU1tC39ZA8AGjp3ctXRokhWW56IXjgZAmsoZCza9G7SbSfj7hxX9F1xyTMzDM0YISbH5pMrdgduTIRPRK1IeKReoYZD"
const FB_CONVERSION_API_ENDPOINT = "https://graph.facebook.com/" + FB_CONVERSION_API_VERSION + "/" + FB_PIXEL_ID + "/events?access_token=" + FB_ACCESS_KEY_TOKEN

const ROOT_TLH_ENDPOINT = "/auth"

async function encodeInSHA256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);                    

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string                  
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

class TLHClassesAPICalls {

    createClassFromTLHClassesDashboard(classData) {

        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/createClassFromTLHClassesDashboard`,{
            classData: classData
        })
    }
    

    getAllTLHLessons() {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/getAllTLHLessons`)
    }

    getIndividualTLHClassInfo(classId) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/getIndividualTLHClassInfo`,{
            classId: classId
        })
    }

    getIndividualTLHStudentInfo(tlhStudentId) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/getIndividualTLHStudentInfo`,{
            tlhStudentId: tlhStudentId
        })
    }

    editIndividualTLHStudentInfo(tlhStudentId, tlhStudentData, parentEnquiryId) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/editIndividualTLHStudentInfo`,{
            tlhStudentId: tlhStudentId,
            tlhStudentData: tlhStudentData,
            parentEnquiryId: parentEnquiryId
        })
    }

    addTermDates(termDates) {
        console.log("Reaching here")
        console.log("Sending term dates")
        console.log(termDates)
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/addTermDates`,{
            termDates: termDates
        })
    }

    getAllTermDates() {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/getAllTermDates`)
    }


    updateAttendanceStatus(lessonDate, studentId, status, tlhClassId, note) {

        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/updateAttendanceStatus`,{
            lessonDate: lessonDate,
            studentId: studentId,
            status: status,
            tlhClassId: tlhClassId,
            note: note
        })
    }

    updateHomeworkData(tlhClassId, updatedHomeworkData) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/updateHomeworkData`,{
            tlhClassId: tlhClassId,
            updatedHomeworkData: updatedHomeworkData,
        })
    }

    getAllClassesRunningForASpecificMonthAndYear(month, year) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/getAllClassesRunningForASpecificMonthAndYear`,{
            month: month,
            year: year,
        })
    }

    printLessonPlansForSpecificLesson(numTeachersCopies, numStudentsCopies, lessonId) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/getAllClassesRunningForASpecificMonthAndYear`,{
            numTeachersCopies: numTeachersCopies,
            numStudentsCopies: numStudentsCopies,
            lessonId: lessonId
        })
    }

    addNoteToTLHStudent(tlhStudentId, newNote) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/addNoteToTLHStudent`,{
            tlhStudentId: tlhStudentId,
            newNote: newNote,
        })
    }

    updateTLHClassLessonNumber(newLessonNumber, classLessonRecordId, applyFutureOnly) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/updateTLHClassLessonNumber`,{
            newLessonNumber: newLessonNumber,
            classLessonRecordId: classLessonRecordId,
            applyFutureOnly: applyFutureOnly
        })
    }
    // addNewAssessmentType(assessmentInfo) {

    //     return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/addNewAssessmentType`,{
    //         assessmentInfo: assessmentInfo
    //     })
    // }

    // getAllAssessmentTypesInfo() {
    //     return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/getAllAssessmentTypesInfo`)
    // }
      
}
export default new TLHClassesAPICalls();
