import React, { useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography, ToggleButton } from '@mui/material';
import {useParams} from "react-router-dom";
import TLHClassesAPICalls from 'services/TLHClassesAPICalls';
import { Button, Modal, Box, TextField } from '@mui/material';
import * as CONSTANTS from "../../StringConstants"
import { useHistory } from "react-router";
import { IconButton } from '@mui/material';
import { Edit, Save } from '@mui/icons-material';

import { Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel } from "@mui/material";

export default function IndividualTLHClassView() {

  const stickyColumnStyle = {
    position: 'sticky',
    left: 0,
    backgroundColor: 'black', // Change to your desired background color
    zIndex: 1, // Ensure it is above other cells
    borderRight: '1px solid white' // Optional: to show a visual separation
  };

  // Define the styled TableCell for sticky column
const StickyTableCell = styled(TableCell)(({ theme }) => ({
  position: 'sticky',
  left: 0,
  // backgroundColor: theme.palette.background.paper, // Adjust the color as needed
  zIndex: 1,
  borderRight: '1px solid #ddd', // Optional: to show a visual separation
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.paper,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.background.paper,
  },
}));

  const [isGettingClassInfo, setIsGettingClassInfo] = useState(true)
  const [tlhClassInfo, setTLHClassInfo] = useState()
  const [classLessonRecords, setClassLessonRecords] = useState()
  const [attendanceStatus, setAttendanceStatus] = useState()
  const [previousStudents, setPreviousStudents] = useState()
  const [currentStudents, setCurrentStudents] = useState({})

  const [isShowingRegister, setIsShowingRegister] = useState(true)
  const [homeworkData, setHomeworkData] = useState({});

  const [selectedAttendance, setSelectedAttendance] = useState({});
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState('');
  const [selectedCell, setSelectedCell] = useState(null);
  const [isHomeworkModalOpen, setIsHomeworkModalOpen] = useState(false)
  const [nextLessonNumber, setNextLessonNumber] = useState()

  const [selectedLesson, setSelectedLesson] = useState(null);
  const [lessonPickerModal, setLessonPickerModal] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [applyFutureOnly, setApplyFutureOnly] = useState(true);
  const [apiStatus, setApiStatus] = useState(null);
  const [selectedClassLessonRecordId, setSelectedClassLessonRecordId] = useState()


  const [newHomework, setNewHomework] = useState({
    lessonDate: '',
    homeworkName: '',
    totalMarks: '',
    studentMarks: {},
    studentComments: {},
  });

  const [selectedHomework, setSelectedHomework] = useState(null);
  const [isEditingHomework, setIsEditingHomework] = useState(false);
  const [editHomeworkId, setEditHomeworkId] = useState(null);
  const [changedHomeworkData, setChangedHomeworkData] = useState({});

  const { tlhClassId } = useParams();
  let history = useHistory();


  useEffect(() => {
    if (isGettingClassInfo) {
      getIndividualTLHClassInfo()
    }
    if (Object.keys(changedHomeworkData).length > 0) { // Ensure that homeworkData is not empty
      updateHomeworkDataInBackend(changedHomeworkData);
    }
  }, [isGettingClassInfo, homeworkData]);

  const updateHomeworkDataInBackend = async (updatedHomeworkData) => {
    try {
      const response = await TLHClassesAPICalls.updateHomeworkData(tlhClassId, updatedHomeworkData); // Adjust this API call based on your implementation
      console.log("Homework data successfully retrieved/updated in backend", response.data);
    } catch (error) {
      console.error("Error updating homework data in backend", error);
    }
  };

  function getNextLesson(lessons) {
    const now = new Date(); // Get current date and time

    // Convert lessonDate and startTime into Date objects and filter future lessons
    const upcomingLessons = lessons
        .map(lesson => {
            const lessonDateTime = new Date(`${lesson.lessonDate}T${lesson.startTime}`);
            return { ...lesson, lessonDateTime };
        })
        .filter(lesson => lesson.lessonDateTime > now) // Keep only future lessons
        .sort((a, b) => a.lessonDateTime - b.lessonDateTime); // Sort by date

    // Return the lessonNumber of the next lesson, or null if no future lesson is found
    return upcomingLessons.length > 0 ? upcomingLessons[0].lessonNumber : null;
}
  

  function getIndividualTLHClassInfo() {

    TLHClassesAPICalls.getIndividualTLHClassInfo(tlhClassId).then(result => {
      console.log(result.data)
      const sortByLessonDate = (lessons) => {
        return lessons.sort((a, b) => new Date(a.lessonDate) - new Date(b.lessonDate));
    };
    
      
      const sortedLessons = sortByLessonDate(result.data.classLessonRecords);
      setNextLessonNumber(getNextLesson(sortedLessons))
      console.log("Sorted Lessons")
      console.log(sortedLessons)


      // Need the list of students - sorted into current and old - need their attendance status for each week 
      // Both need to be matched up - will do so by index
      // Students might have the same name so will use a dict - key = tlhStudentId, value = firstName + surname
      // Attendance status will be a dict of dicts - key = date, value = dict <tlhStudentId, attendanceStatus>

      var currentStudents = {}
      var previousStudents = {}
      var attendanceStatus = {}
      var homeworkData = {}; 

      // Iterate over all sorted lessons
      // For each, create a dict to put in attendance status for the register for that lesson
        // iterate over all student lesson records
      for (var i = 0; i < sortedLessons.length; i ++) {
        var lesson = sortedLessons[i]
        // console.log("Lesson " + i)
        // console.log(lesson)
        var studentIndividualRecords = lesson.individualStudentLessonRecords
        // console.log("Lesson")
        // console.log(lesson)
        var registerForThisDate = {}
        // Add student's attendance status to the register (k,v pair)
        // Add student's name to either previous or current students - base on if date is before or after today 
        // This will put all students initially in previous students, should pull them out of previous if in a lesson after today's date
        for (var j = 0; j < studentIndividualRecords.length; j ++) {
          // Create register with attendance
          var studentRecord = studentIndividualRecords[j]
          // console.log("Student record")
          // console.log(studentRecord)
          registerForThisDate[studentRecord.student.tlhStudentId] = studentRecord.attendanceStatus

          // Split students into previous and current students
          if (isLessonDateTodayOrFuture(lesson.lessonDate)) {
            currentStudents[studentRecord.student.tlhStudentId] = studentRecord.student.firstName + " " + studentRecord.student.surname
          } else {
            previousStudents[studentRecord.student.tlhStudentId] = studentRecord.student.firstName + " " + studentRecord.student.surname
          }

            // Extract homework data for each student
          studentRecord.homeworkRecords.forEach(hw => {
            if (!homeworkData[lesson.lessonDate]) {
              homeworkData[lesson.lessonDate] = [];
            }
            
            let existingHomework = homeworkData[lesson.lessonDate].find(h => h.homeworkName === hw.homeworkTitle);
            
            if (!existingHomework) {
              existingHomework = {
                id: hw.id,
                homeworkName: hw.homeworkTitle,
                totalMarks: hw.totalMarks,
                studentMarks: {},
                studentComments: {}
              };
              homeworkData[lesson.lessonDate].push(existingHomework);
            }
            
            existingHomework.studentMarks[studentRecord.student.tlhStudentId] = hw.studentMarks;
            existingHomework.studentComments[studentRecord.student.tlhStudentId] = hw.homeworkNotes;
          });
        }
        attendanceStatus[lesson.lessonDate] = registerForThisDate

      }
      
      console.log("classLessonRecords")
      console.log(sortedLessons)
      setClassLessonRecords(sortedLessons)
      setTLHClassInfo(result.data)
      setAttendanceStatus(attendanceStatus)
      setPreviousStudents(previousStudents)
      setCurrentStudents(currentStudents)
      setHomeworkData(homeworkData);  // Set homework data state


      setIsGettingClassInfo(false)
    })
  }

  function isLessonDateTodayOrFuture(lessonDate) {
    // Parse the lesson date
    const lessonDateObj = new Date(lessonDate);
  
    // Get today's date and reset time part to 00:00:00
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    // Compare lesson date with today
    return lessonDateObj >= today;
  }

  function getAttendanceStatusForStudent(lessonDate, studentId) {
    var relevantRegister = attendanceStatus[lessonDate]

    // CLASS_NOT_HAPPENED_YET,
    // HOLIDAY,
    // PRESENT,
    // UNEXPLAINED_ABSENCE,
    // SICK,
    // LATE,
    // OTHER

    if (!relevantRegister[studentId] || relevantRegister[studentId] == "CLASS_NOT_HAPPENED_YET") {
      return "-"
    }

    if (relevantRegister[studentId] == "UNEXPLAINED_ABSENCE") {
      return "U.A"
    }
    return relevantRegister[studentId].charAt(0)
  }

    // Handle selection
    const handleLessonSelect = (event) => {
      setSelectedLesson(event.target.value);
      setConfirmModalOpen(true);
  };

  

  const handleSelectChange = (lessonDate, studentId, value, lessonId) => {
    setSelectedAttendance(prev => ({
      ...prev,
      [lessonDate]: {
        ...prev[lessonDate],
        [studentId]: value
      }
    }));
    if (value === "OTHER") {
      setSelectedCell({lessonDate, studentId});
      console.log(selectedCell)
      setOpen(true);
    } else {
      // Send update to backend for other statuses
      TLHClassesAPICalls.updateAttendanceStatus(lessonDate, studentId, value, tlhClassId);
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    setSelectedCell(null);
  };

  const handleNoteSubmit = () => {
    if (selectedCell) {
      // Save the note with the attendance status
      console.log(`Saving note for ${selectedCell.studentId} on ${selectedCell.lessonDate}: ${note}`);
      TLHClassesAPICalls.updateAttendanceStatus(selectedCell.lessonDate, selectedCell.studentId, "Other", tlhClassId, note);
    }
    handleModalClose();
  };


  const handleOpenNewHomeworkModal = (lessonDate) => {
    setNewHomework({
      lessonDate,
      homeworkName: '',
      totalMarks: '',
      studentMarks: {},
      studentComments: {},
    });
    setIsHomeworkModalOpen(true);
    setIsEditingHomework(false);
  };

  const handleOpenEditHomeworkModal = (lessonDate, homework) => {
    setNewHomework({
      lessonDate,
      homeworkName: homework.homeworkName,
      totalMarks: homework.totalMarks,
      studentMarks: homework.studentMarks,
      studentComments: homework.studentComments,
    });
    setIsHomeworkModalOpen(true);
    setIsEditingHomework(true);
    setEditHomeworkId(homework.id);
  };


  const handleCloseNewHomeworkModal = () => {
    setIsHomeworkModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewHomework({ ...newHomework, [name]: value });
  };


  const handleStudentMarkChange = (studentId, mark, comment) => {
    setNewHomework((prevState) => ({
      ...prevState,
      studentMarks: { ...prevState.studentMarks, [studentId]: mark },
      studentComments: { ...prevState.studentComments, [studentId]: comment },
    }));
  };


  const handleSaveHomework = () => {
    setHomeworkData((prevState) => {
      const lessonHomework = prevState[newHomework.lessonDate] || [];
      const updatedHomework = {
        id: editHomeworkId || new Date().getTime(),
        homeworkName: newHomework.homeworkName,
        totalMarks: newHomework.totalMarks,
        studentMarks: newHomework.studentMarks,
        studentComments: newHomework.studentComments,
      };

      let newLessonHomework;
      const existingHomeworkIndex = lessonHomework.findIndex(hw => hw.id === updatedHomework.id);
      if (existingHomeworkIndex >= 0) {
        newLessonHomework = [...lessonHomework];
        newLessonHomework[existingHomeworkIndex] = updatedHomework;
      } else {
        newLessonHomework = [...lessonHomework, updatedHomework];
      }

      return {
        ...prevState,
        [newHomework.lessonDate]: newLessonHomework,
      };
    });


    setChangedHomeworkData((prevState) => {
      const lessonHomework = prevState[newHomework.lessonDate] || [];
      const updatedHomework = {
        id: editHomeworkId || new Date().getTime(),
        homeworkName: newHomework.homeworkName,
        totalMarks: newHomework.totalMarks,
        studentMarks: newHomework.studentMarks,
        studentComments: newHomework.studentComments,
      };

      let newLessonHomework;
      const existingHomeworkIndex = lessonHomework.findIndex(hw => hw.id === updatedHomework.id);
      if (existingHomeworkIndex >= 0) {
        newLessonHomework = [...lessonHomework];
        newLessonHomework[existingHomeworkIndex] = updatedHomework;
      } else {
        newLessonHomework = [...lessonHomework, updatedHomework];
      }

      return {
        ...prevState,
        [newHomework.lessonDate]: newLessonHomework,
      };
    });

    handleCloseNewHomeworkModal();
  };


  function formatAttendanceStatusString(status) {
    // Convert to lowercase
    let formatConversionStatus = status.toLowerCase();
    
    // Replace all occurrences of underscores with spaces
    formatConversionStatus = formatConversionStatus.replace(/_/g, " ");
    
    // Capitalize the first letter
    formatConversionStatus = formatConversionStatus.charAt(0).toUpperCase() + formatConversionStatus.slice(1);
    
    return formatConversionStatus;
  }

  function goToIndividualClass(relatedTlhClassId) {
    history.push(CONSTANTS.ViewTLHClass + "/" + `${relatedTlhClassId}`)
    window.location.reload()
  }

  function goToIndividualStudentProfile(tlhStudentId) {
    console.log("TLH Student Id")
    console.log(tlhStudentId)
    console.log("Pushing to " + CONSTANTS.ViewTLHStudentProfile + "/" + `${tlhStudentId}`)
    history.push(CONSTANTS.ViewTLHStudentProfile + "/" + `${tlhStudentId}`)
    // window.location.reload()
  }

  const handleToggle = () => {
    setIsShowingRegister(!isShowingRegister);
  };

   // Simulated API call
   const updateLesson = async () => {
    setConfirmModalOpen(false);
    setLessonPickerModal(false)
    try {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate API call
        TLHClassesAPICalls.updateTLHClassLessonNumber(selectedLesson, selectedClassLessonRecordId, applyFutureOnly)
        setApiStatus("success");
    } catch (error) {
        setApiStatus("failure");
    }
};

  function openModalAndSetClassLessonRecord(classLessonRecordId) {
    console.log("Class lesson record object")
    console.log(classLessonRecordId)
    setLessonPickerModal(true)
    setSelectedClassLessonRecordId(classLessonRecordId.id)
  }
  


  return (
    
    <div>

       {isGettingClassInfo ? <></> :
         <div style={{ overflowX: 'auto', width: '100%'}}>

          <Typography variant='h5' style={{padding: "15px", textAlign: "center"}}>
            {tlhClassInfo.className + " - " + tlhClassInfo.subject} - Teacher 1/Teacher 2
          </Typography>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px 15px 10px 15px' }}>
            <Typography>
              Time: {tlhClassInfo.startTime}
            </Typography>

            {/* Display Next Lesson Number */}
            {/* <Typography variant="h6" onClick={() => setLessonPickerModal(true)} style={{ cursor: "pointer" }}>
                Upcoming Lesson Number: {selectedLesson || nextLessonNumber}
            </Typography> */}

            {/* <Typography>
              Next Lesson Number: {nextLessonNumber}
            </Typography> */}


            {/* Dropdown Modal */}
            <Dialog open={lessonPickerModal} onClose={() => setLessonPickerModal(false)}>
                <DialogTitle>Select a Lesson Number</DialogTitle>
                <DialogContent>
                    <Select fullWidth value={selectedLesson || ""} onChange={handleLessonSelect}>
                    {Array.from({ length: 50 }, (_, i) => i + 1).map(number => (
                        <MenuItem key={number} value={number}>
                            Lesson {number}
                        </MenuItem>
                    ))}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLessonPickerModal(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Modal */}
            <Dialog open={confirmModalOpen} onClose={() => setConfirmModalOpen(false)}>
              <DialogTitle>Update Lesson {selectedLesson}</DialogTitle>
              <div style={{padding: '0px', marginInline: '20px'}}> 
                  Previous and past lesson numbers will be updated accordingly
                  unless the box below is selected (note your action will be logged).
              </div>
                <DialogContent>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={applyFutureOnly}
                                onChange={(e) => setApplyFutureOnly(e.target.checked)}
                            />
                        }
                        label="Only update lesson numbers for future lessons"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmModalOpen(false)}>Cancel</Button>
                    <Button onClick={() => updateLesson()} color="primary" variant="contained">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Success/Failure Modal */}
            <Dialog open={apiStatus !== null} onClose={() => setApiStatus(null)}>
                <DialogTitle>{apiStatus === "success" ? "Update Successful!" : "Update Failed"}</DialogTitle>
                <DialogActions>
                  <Button onClick={() => window.location.reload()}>OK</Button>
                </DialogActions>
            </Dialog>

            <ToggleButton
              value="check"
              selected={isShowingRegister}
              onChange={handleToggle}
              style={{ backgroundColor: isShowingRegister ? '#0056b3' : '#e0e0e0', color: isShowingRegister ? '#fff' : '#000' }}
            >
              {isShowingRegister ? 'View Marks' : 'View Register'}
            </ToggleButton>
          </div>

          <Typography style={{paddingLeft: "15px", paddingBottom: "10px"}}>
            Related Classes (Click to View):
          </Typography>

          {tlhClassInfo.relatedClasses.map((relatedClass) => (
              <button onClick={() => goToIndividualClass(relatedClass.classId)}>
                <Typography style={{paddingLeft: "15px", paddingBottom: "10px"}}>
                  <u>{relatedClass.className + " - " + relatedClass.subject}</u>
                </Typography>
              </button>
          ))}

      { isShowingRegister ?

        <div style={{ overflowX: 'auto'}}>
        <Table size="small" style={{ minWidth: '1000px', tableLayout: 'auto'}}>
          <TableHead>
            <TableRow style={{ backgroundColor: '#f2f2f2' }}>
              <StickyTableCell></StickyTableCell>
              {classLessonRecords.map((lessonRecord, index) => (
                <TableCell onClick={() => openModalAndSetClassLessonRecord(lessonRecord)} key={lessonRecord.lessonDate} style={{ textAlign: 'center', minWidth: '150px', cursor: "pointer" }}>
                  Lesson Plan {lessonRecord.lessonNumber}
                </TableCell>
              ))}
            </TableRow>
            <TableRow style={{ backgroundColor: '#f2f2f2' }}>
              <StickyTableCell></StickyTableCell>
              {classLessonRecords.map((lessonRecord) => (
                <TableCell key={lessonRecord.lessonDate} style={{ textAlign: 'center', minWidth: '150px' }}>
                  <div>
                    {lessonRecord.lessonDate}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{textAlign: "center"}}>
            {Object.entries(currentStudents).map(([studentId, studentName]) => (
              <TableRow key={studentId}>
                 <StickyTableCell>
                  <button onClick={() => goToIndividualStudentProfile(studentId)}>
                    <u>{studentName}</u>
                  </button>
                </StickyTableCell>
                {classLessonRecords.map((lessonRecord) => (
                  <TableCell key={lessonRecord.lessonDate}>
                    <select
                      value={
                        selectedAttendance[lessonRecord.lessonDate]?.[studentId] ||
                        attendanceStatus[lessonRecord.lessonDate]?.[studentId] || 
                        getAttendanceStatusForStudent(lessonRecord.lessonDate, studentId)
                      }
                      onChange={(e) => handleSelectChange(lessonRecord.lessonDate, studentId, e.target.value, lessonRecord.id)}
                    >
                      {CONSTANTS.TLHClassAttendanceOptions.map(option => (
                        <option key={option} value={option}>{formatAttendanceStatusString(option)}</option>
                      ))}
                    </select>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table> 
        </div>

        : 

        <div style={{ overflowX: 'auto' }}>
        <Table size="small" style={{ tableLayout: 'auto', minWidth: '1000px' }}>
          <TableHead>
            <TableRow style={{ backgroundColor: '#f2f2f2' }}>
              <StickyTableCell></StickyTableCell>
              {classLessonRecords.map((lessonRecord, index) => (
                <TableCell key={lessonRecord.lessonDate} style={{ textAlign: 'center', minWidth: '150px' }}>
                  Lesson Plan {lessonRecord.lessonNumber}
                </TableCell>
              ))}
            </TableRow>
            <TableRow style={{ backgroundColor: '#f2f2f2' }}>
              <StickyTableCell></StickyTableCell>
              {classLessonRecords.map((lessonRecord) => (
                <TableCell key={lessonRecord.lessonDate} style={{ textAlign: 'center', minWidth: '150px' }}>
                  <div>
                    {lessonRecord.lessonDate}
                    <IconButton onClick={() => handleOpenNewHomeworkModal(lessonRecord.lessonDate)}>
                      <Edit />
                    </IconButton>
                  </div>
                </TableCell>
              ))}
            </TableRow>
            <TableRow style={{ backgroundColor: '#e6f7ff' }}>
              <StickyTableCell>Homework Title</StickyTableCell>
              {classLessonRecords.map((lessonRecord) => (
                <TableCell key={lessonRecord.lessonDate} style={{ minWidth: '150px', padding: 0 }}>
                  <Table size="small" style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}>
                    <TableBody>
                      <TableRow>
                        {homeworkData[lessonRecord.lessonDate]?.map((hw, index) => (
                          <TableCell key={index} style={{ textAlign: 'center', minWidth: '150px', padding: '8px' }}>
                            {hw.homeworkName}
                            <IconButton onClick={() => handleOpenEditHomeworkModal(lessonRecord.lessonDate, hw)}>
                              <Edit />
                            </IconButton>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableCell>
              ))}
            </TableRow>
            <TableRow style={{ backgroundColor: '#e6f7ff' }}>
              <StickyTableCell>Total Marks</StickyTableCell>
              {classLessonRecords.map((lessonRecord) => (
                <TableCell key={lessonRecord.lessonDate} style={{ justifyContent: 'center', minWidth: '150px', padding: 0 }}>
                  <Table size="small" style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}>
                    <TableBody>
                      <TableRow>
                        {homeworkData[lessonRecord.lessonDate]?.map((hw, index) => (
                          <TableCell key={index} style={{ textAlign: 'center', minWidth: '150px', padding: '8px' }}>
                            {hw.totalMarks || 'N/A'}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(currentStudents).map(([studentId, studentName]) => (
              <TableRow key={studentId}>
                <StickyTableCell>{studentName}</StickyTableCell>
                {classLessonRecords.map((lessonRecord) => (
                  <TableCell key={lessonRecord.lessonDate} style={{ minWidth: '150px', padding: 0 }}>
                    <Table size="small" style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}>
                      <TableBody>
                        <TableRow>
                          {homeworkData[lessonRecord.lessonDate]?.map((hw, index) => (
                            <TableCell key={index} style={{ textAlign: 'center', minWidth: '150px', padding: '8px' }}>
                              {hw.studentMarks[studentId] || 'N/A'}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      }
       <button
          value="check"
          // selected={isShowingRegister}
          // onChange={handleToggle}
          style={{ backgroundColor: isShowingRegister ? '#0056b3' : '#e0e0e0', color: isShowingRegister ? '#fff' : '#000' }}
        >
          {'Add Student'}
        </button>
    </div>
     
    }
{/* for student's who's attendance is marked as other */}
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Add a Note
          </Typography>
          <TextField
            id="modal-description"
            label="Note"
            fullWidth
            value={note}
            onChange={(e) => setNote(e.target.value)}
            multiline
            rows={4}
            variant="outlined"
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={handleModalClose} color="secondary" variant="contained" sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button onClick={handleNoteSubmit} color="primary" variant="contained">
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
{/* Modal to add extra hw */}
      <Modal open={isHomeworkModalOpen} onClose={handleCloseNewHomeworkModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            maxHeight: '80vh', // Set a maximum height for the modal
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflow: 'auto', // Make the content scrollable
          }}
        >
          <Typography variant="h6" component="h2">
            {isEditingHomework ? 'Edit Homework Marks' : 'Add Homework Marks'}
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Homework Name"
            name="homeworkName"
            value={newHomework.homeworkName}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Total Marks"
            name="totalMarks"
            type="number"
            value={newHomework.totalMarks}
            onChange={handleInputChange}
          />
          <div style={{ display: 'flex', marginTop: '10px' }}>
            <Typography variant="h6" style={{ flex: '1 1 25%', padding: '10px' }}>
              Marks
            </Typography>
            <Typography variant="h6" component="h2" style={{ flex: '1 1 75%', padding: '10px' }}>
              Comments
            </Typography>
          </div>
          {Object.entries(currentStudents).map(([studentId, studentName]) => (
            <div key={studentId} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', gap: '10px' }}>
              <TextField
                style={{ flex: '1 1 25%' }}
                margin="normal"
                label={studentName}
                type="number"
                value={newHomework.studentMarks[studentId] || ''}
                onChange={(e) => handleStudentMarkChange(studentId, e.target.value, newHomework.studentComments[studentId] || '')}
              />
              <TextField
                style={{ flex: '1 1 75%' }}
                margin="normal"
                label="Comments"
                value={newHomework.studentComments[studentId] || ''}
                onChange={(e) => handleStudentMarkChange(studentId, newHomework.studentMarks[studentId] || '', e.target.value)}
              />
            </div>
          ))}
          <Button variant="contained" color="primary" onClick={handleSaveHomework} startIcon={<Save />}>
            Save
          </Button>
        </Box>
      </Modal>
    
    </div>
  );
}

